import { post } from '@/request/ygjRequest';

export function vcode(params) {
  return post('/port/qzjb/code', params);
}

export function login(params) {
  return post('/port/qzjb/login', params);
}

export function validate(params) {
  return post('/port/qzjb/repeat/validate', params);
}

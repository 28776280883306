import axios from 'axios'; // 引入axios
import qs from 'qs';
import md5 from 'js-md5';

let baseUrl = 'https://ratshyh.wzgo.cn';
//let baseUrl = 'http://localhost:8080';
let MyPortKey = 'rarb66886688@qzjb';

const rarbhjmInstance = axios.create({
  baseURL: baseUrl, // 根据项目设置 url = base url + request url
  timeout: 10000 // request timeout
});

export function get(url, params) {
  // get封装
  return new Promise((resolve, reject) => {
    rarbhjmInstance
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url, params) {
  let times = new Date().getTime();
  let sign = md5(times + MyPortKey);
  if (!params) {
    params = {};
  }
  params.sign = sign;
  params.timestamp = times;
  // post封装
  return new Promise((resolve, reject) => {
    rarbhjmInstance
      .post(url, qs.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export default rarbhjmInstance;
